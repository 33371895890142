































































































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {ApplicationUser, Role} from "@/data/model/ApplicationUser";
import AddressForm from "@/components/AddressForm.vue";
import {Project} from "@/data/model/Project";
import {ApplicationUserBuilder} from "@/data/builder/ApplicationUserBuilder";
import Base, {Form} from "@/data/model/Base";

@Component({
  components: {AddressForm}
})

export default class UserForm extends Base {
  @Prop({required: true})
  public value!: ApplicationUser

  @Prop({required: true, default: true})
  public isUpdatingUser!: boolean

  @Ref('form')
  form!: Form

  private isFormValid = false
  private showPw = false
  private isSaving = false

  public projects: Array<{ text: string, value: string }> = []

  constructor() {
    super('user-form')
  }

  mounted() {
    if(!this.isUpdatingUser){
      this.value = new ApplicationUserBuilder().build()
    }
    this.$store.dispatch('getAllProjects').then((p: Array<Project>) => {
      p.forEach(projekt => {
            this.projects.push({text: `${projekt.id} - ${projekt.name}`, value: this.fomartID(projekt.id)})
          }
      )
    })
  }

  // Form frunktions
  public validateAndSend() {
    if (this.form.validate()) {
      this.send()
    }
  }

  private send() {
    this.isSaving = true
    if (this.isUpdatingUser) {
      this.$store.dispatch('updateUser', this.value).then(() => {
        this.cancel()
      }).catch(() => {
        this.isSaving = false
      })
    } else {
      this.$store.dispatch('createUser', this.value).then(() => {
        this.$emit('action', 'creating')
      }).catch(() => {
        this.isSaving = false
      })
    }

  }

  public cancel() {
    //this.$refs.userFrom.reset()
    this.$emit('action', 'abort')
  }

  public changePW() {
    this.value.password = new ApplicationUserBuilder().makePW(8)
  }

  public fomartID(id:string) {
    return id
        .toLowerCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '')
        .replaceAll(',', '')
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('#', '')
  }

  get getProjects() {
    return this.projects
  }

  get notEmptyRule() {
    return [
      (v: string) => !!v || this.$t('form.notEmpty'),
    ]
  }

  get arrayNotEmptyRule() {
    return [
      (v: []) => v.length != 0 || this.$t('form.notEmpty'),
    ]
  }

  get roles() {
    return Object.keys(Role).map(r => {
      return {text: r.toLowerCase(), value: r}
    })
  }

}
