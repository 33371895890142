





























































































import {Component} from "vue-property-decorator";
import {ApplicationUser, Role} from "@/data/model/ApplicationUser";
import {CustomerBuilder} from "@/data/builder/CustomerBuilder";
import UserForm from "@/components/UserForm.vue";
import {ApplicationUserBuilder} from "@/data/builder/ApplicationUserBuilder";
import Base from "@/data/model/Base";

@Component({
  components: {UserForm},
})

export default class ManageUser extends Base {
  private listOfUSer = new Array<ApplicationUser>()
  private header = [
      this.getLangText('name'),
      this.getLangText('username'),
      this.getLangText('role'),
      this.getLangText('action'),
  ]

  private headers = [
    {text: this.getLangText('name'), value: 'name'},
    {text: this.getLangText('username'), value: 'username'},
    {text: this.getLangText('role'), value: 'role'},
    {text: this.getLangText('action'), value: 'action'}
  ]

  constructor() {
    super('manage-user')
  }

  private selectedUser: ApplicationUser = {
    username: '',
    password: '',
    role: Role.USER
  }
  private isUpdatingUser = false
  private createOrUpdate = false

  private newPasswordDialog = false
  private newPasswordUser = {}

  private search = ''

  created() {
    this.selectedUser = this.emptyUser()
    this.refreshUser()
  }

  private createUser() {
    this.isUpdatingUser= false
    this.createOrUpdate = !this.createOrUpdate
  }

  private updateUser(user:ApplicationUser){
    if(!user.customer){
      user.customer = new CustomerBuilder().build()
    }

    this.isUpdatingUser= true
    this.createOrUpdate = !this.createOrUpdate
    this.selectedUser = user
  }

  public updatePassword(user: ApplicationUser){
    user.password = this.makePW(8)
    this.newPasswordUser = {
      username: user.username,
      password: user.password
    }
    this.$store.dispatch('resetUserPassword', user)
    this.newPasswordDialog = true
  }

  private deleteUser(user:ApplicationUser){
    this.$store.dispatch('deleteUser', user).then(e => this.refreshUser())
  }

  private formIsCliecked(event: string) {
    switch (event) {
      case 'abort': {
        this.createOrUpdate = !this.createOrUpdate
        this.selectedUser = this.emptyUser()
        break;
      }
      case 'updating': {
        break;
      }
      case 'creating': {
        this.createOrUpdate = !this.createOrUpdate
        this.selectedUser = this.emptyUser()
        this.refreshUser()
        break;
      }
    }
  }

  private refreshUser() {
    this.$store.dispatch('getAllUsers').then(users => {
      this.listOfUSer = users
    })
  }

  private emptyUser(): ApplicationUser {
    return new ApplicationUserBuilder()
        .role(Role.USER)
        .customer(new CustomerBuilder().build())
        .build()
  }

  protected makePW(length: number):string {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
